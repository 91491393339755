import { StringResources as Res, translate as t } from "utils/language/languageResource";
import { useParams, useNavigate } from "react-router-dom";
import "./changeForgotPassword.scss";
import { useAppDispatch } from "redux/store";
import SwcButton from "components/swcButton/swcButton";
import { Formik } from "formik";
import { customerChangeForgotPasswordInitialValues } from "utils/formikValidation/formikInitialValues";
import { customerChangeForgotPasswordValidationSchema } from "utils/formikValidation/validationSchema";
import { onSubmitCustomerChangeForgotPassword } from "utils/formikValidation/formikOnSubmit";
import { PAGE_ROUTES } from "utils/paths";
import SwcInputControlFormik from "components/swcInputControlFormik/swcInputControlFormik";
import { customerChangeForgotPasswordInputData } from "utils/formikValidation/formInputData";
import SwcCircle from "components/swcCircle/swcCircle";

const ChangeForgotPassword = () => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const params = useParams();

    const { tokenId } = params;

    if (!tokenId) {
        navigate(PAGE_ROUTES.Global);
    }

    return (
        <Formik
            initialValues={customerChangeForgotPasswordInitialValues}
            validationSchema={customerChangeForgotPasswordValidationSchema}
            onSubmit={values => {
                onSubmitCustomerChangeForgotPassword(values, tokenId!, dispatch, navigate);
            }}
        >
            {({ handleSubmit }) => (
                <div className="customer-change-forgot-password__container">
                    <SwcCircle />
                    <div className="customer-change-forgot-password__form">
                        <div className="customer-change-forgot-password__title-text">
                            {t(Res.ChangeForgottenPassword)}
                        </div>
                        {customerChangeForgotPasswordInputData.data.map((val, index) => {
                            return (
                                <SwcInputControlFormik
                                    key={index}
                                    name={val.name}
                                    inputType={val.inputType}
                                    htmlFor={val.htmlFor}
                                    labelTitle={val.labelTitle}
                                    styleDefault="customer-change-forgot-password__input--default"
                                    styleError="customer-change-forgot-password__input--error"
                                    styleLabel="customer-change-forgot-password__label"
                                    errorInsideInput={false}
                                />
                            );
                        })}
                        <SwcButton
                            text={t(Res.Submit)}
                            onClickHandler={handleSubmit}
                            style="company-change-forgot-password__button"
                        />
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default ChangeForgotPassword;
