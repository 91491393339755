import classNames from "classnames";
import { PlusSquareFill, XSquareFill } from "react-bootstrap-icons";
import { SwcButtonIcon } from "utils/enums";
import "./swcButton.scss";

export interface ISwcButtonProps {
    text: string;
    onClickHandler: (data?: any) => void;
    disabled?: boolean;
    buttonIcon?: SwcButtonIcon;
    data?: any;
    style?: string;
    textStyle?: string;
}

function SwcButton(props: ISwcButtonProps) {
    const { text, onClickHandler, data } = props;

    const onButtonClickHandler = () => {
        onClickHandler(data);
    };

    let buttonIcon = undefined;

    switch (props.buttonIcon) {
        case SwcButtonIcon.PlusSquareFilled:
            buttonIcon = <PlusSquareFill />;
            break;
        case SwcButtonIcon.XSquareFill:
            buttonIcon = <XSquareFill />;
            break;
        default:
            break;
    }

    return (
        <div
            className={classNames("swc-button", {
                disabled: props.disabled,
            })}
            onClick={onButtonClickHandler}
        >
            <div className={`swc-button-div ` + props.style}>
                <div className={`swc-button-div-text ` + props.textStyle}>{text}</div>
                {buttonIcon}
            </div>
        </div>
    );
}

export default SwcButton;
