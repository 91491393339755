import { StringResources as Res, translate as t } from "utils/language/languageResource";
import LandingPageAdvantage from "components/landingPageAdvantage/landingPageAdvantage";
import AndroidIos from "assets/images/landingPageBenefits/android-ios.jpg";
import Register from "assets/images/landingPageBenefits/register.png";
import Itinerary from "assets/images/landingPageBenefits/itinerary.png";
import EarnPoints from "assets/images/landingPageBenefits/earn-points.png";
import "./landingPageBenefits.scss";
import { TopbarNavigation } from "utils/constants";

const LandingPageBenefits = () => {
    const landingPageBenefitsList = [
        {
            image: AndroidIos,
            title: (
                <>
                    {`${t(Res.DownloadRavaApp)} `}
                    <a
                        href="https://play.google.com/store/apps/details?id=moberg.swc.mobile"
                        className="landing-page-benefits__advantages-grid--link-text"
                    >
                        {`${t(Res.GooglePlayStoreAndroid)} `}
                    </a>
                    {`${t(Res.Or).toLocaleLowerCase()} `}
                    <a
                        href="https://apps.apple.com/hr/app/rava/id6466628351?platform=iphone"
                        className="landing-page-benefits__advantages-grid--link-text"
                    >
                        {t(Res.AppleStoreIOS)}
                    </a>
                </>
            ),
        },
        {
            image: Register,
            title: t(Res.RavaBenefitsTitle_2),
        },
        {
            image: Itinerary,
            title: t(Res.RavaBenefitsTitle_3),
        },
        {
            image: EarnPoints,
            title: t(Res.RavaBenefitsTitle_4),
        },
    ];
    return (
        <section
            className="landing-page-what-is-ekolure"
            id={TopbarNavigation.ravaBenefits}
        >
            <div className="landing-page-benefits-container">
                <div className="landing-page-benefits__header">
                    <h1 className="landing-page-benefits__header-title">{t(Res.WhatAreRavaBenefits)}</h1>
                    <p className="landing-page-benefits__header-description">{t(Res.RavaBenefitsDescription_1)}</p>
                    <p className="landing-page-benefits__header-description">{t(Res.RavaBenefitsDescription_2)}</p>
                    <p className="landing-page-benefits__header-description">{t(Res.RavaBenefitsDescription_3)}</p>
                </div>
                <div className="landing-page-benefits__advantages-grid">
                    {landingPageBenefitsList.map(x => (
                        <LandingPageAdvantage
                            image={x.image}
                            title={x.title}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default LandingPageBenefits;
