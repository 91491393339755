import { changeForgottenPasswordThunk } from "redux/actions/authActions";
import { executeContainerThunkDispatch } from "utils/helperFunctions";
import { PAGE_ROUTES } from "utils/paths";
import { ICustomerChangeForgotPasswordFormik } from "./validationModelInterface";

export const onSubmitCustomerChangeForgotPassword = async <T extends ICustomerChangeForgotPasswordFormik>(
    values: T,
    forgotPasswordToken: string,
    dispatch: any,
    navigate: any
) => {
    const { customerPassword } = values;

    let result = await executeContainerThunkDispatch(dispatch, changeForgottenPasswordThunk, {
        newPassword: customerPassword,
        forgotPasswordToken: forgotPasswordToken,
    });

    if (result.isValid) {
        navigate(PAGE_ROUTES.Global);
    }
};
