import { useSelector } from "react-redux";
import classNames from "classnames";
import Lottie from "react-lottie-player";
import lottieJson from "utils/lottie/loader.json";
import "./swcMainLoader.scss";

export interface SwcMainLoaderProps {
    displayLoaderSelector: any;
}

export function SwcMainLoader(props: SwcMainLoaderProps) {
    const isLoading = useSelector((state: any) => props.displayLoaderSelector(state));
    return (
        <div
            className={classNames("swc-loader", {
                show: isLoading.length > 0,
            })}
        >
            <Lottie
                loop={isLoading.length > 0}
                animationData={lottieJson}
                play
            />
        </div>
    );
}

export default SwcMainLoader;
