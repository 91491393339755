export enum SupportedLanguageEnum {
    Croatian = 1,
    English = 2,
}

export enum UserLoggedInStateEnum {
    Uninitialized = 0,
    Pending = 1,
    LoggedIn = 2,
    Unauthorized = 3,
    Expired = 4,
}

export enum SwcButtonIcon {
    PlusSquareFilled = 0,
    XSquareFill = 1,
}

export enum AccessHocLoginRuleEnum {
    IgnoreLoginState = 0,
    OnlyLoggedInState = 1,
    OnlyNotLoggedInState = 2,
}
