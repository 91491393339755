import { Outlet, Route, Routes } from "react-router-dom";
import SwcMainLoader from "container/swcMainLoader/swcMainLoader";
import { PAGE_ROUTES } from "utils/paths";
import { ToastContainer } from "react-toastify";
import "./app.scss";
import "react-toastify/dist/ReactToastify.css";
import { displayLoaderSelector } from "redux/selectors/authSelectors";
import PathsAndElements from "utils/PathsAndElements";

const App = () => {
    return (
        <div className="app">
            <SwcMainLoader displayLoaderSelector={displayLoaderSelector} />
            <ToastContainer
                position="top-center"
                autoClose={3000}
                className={"swc-toast"}
            />
            <Routes>
                <Route
                    path={PAGE_ROUTES.Global}
                    element={<Outlet />}
                >
                    {PathsAndElements.map(route => (
                        <Route
                            path={route.path}
                            element={route.element}
                        />
                    ))}
                </Route>
            </Routes>
        </div>
    );
};

export default App;
