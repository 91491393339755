import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    EmailVerificationRequest,
    EmailVerificationResponse,
    UserChangeForgottenPasswordRequest,
    UserChangeForgottenPasswordResponse,
} from "redux/models/authModels";
import { AuthEmailVerification, AuthServiceChangeForgottenPassword } from "redux/services/account.services";

export const authEmailVerificationThunk = createAsyncThunk<EmailVerificationResponse, EmailVerificationRequest>(
    "auth/email-verification",
    async (request, thunkAPI) => {
        let response = await AuthEmailVerification(request);
        return response.data;
    }
);

export const changeForgottenPasswordThunk = createAsyncThunk<
    UserChangeForgottenPasswordResponse,
    UserChangeForgottenPasswordRequest
>("auth/change-forgotten-password", async (request, thunkAPI) => {
    let response = await AuthServiceChangeForgottenPassword(request);
    return response.data;
});
