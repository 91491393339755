import { StringResources as Res, translate as t } from "utils/language/languageResource";
import { Image } from "react-bootstrap";
import RightImage from "assets/images/landingPageHeader/right-image.jpg";
import "./landingPageHeader.scss";
import LandingPageDots from "components/landingPageDots/landingPageDots";
import { TopbarNavigation } from "utils/constants";

const LandingPageHeader = () => {
    return (
        <section
            className="landing-page-header-container"
            id={TopbarNavigation.home}
        >
            <div className="landing-page-header__left">
                <div>
                    <h1 className="landing-page-header__left--h1-text">{t(Res.RavaSmartWasteSorting)}</h1>
                    <div className="landing-page-header__left--div-text">{t(Res.LandingPageHomeDescription)}</div>
                </div>
            </div>
            <div className="landing-page-header__right">
                <div className="landing-page-header__right-image-and-text">
                    <Image
                        className="landing-page-header__right-image"
                        src={RightImage}
                    />
                    <div className="landing-page-header__right-dots">
                        <LandingPageDots />
                    </div>
                </div>
                <div className="landing-page-header__grey-background" />
            </div>
        </section>
    );
};

export default LandingPageHeader;
