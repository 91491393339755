import { StringResources as Res, translate as t } from "utils/language/languageResource";
import { Link } from "react-router-dom";
import { PATHS } from "utils/paths";
import ScaleLeadImage_1 from "assets/images/news/1/scale.png";
import RavaBottomImage_1_1 from "assets/images/news/1/rava.png";
import LeadImage_2 from "assets/images/news/2/lead-image.jpg";
import Return from "assets/images/news/return.png";
import "./newsPage.scss";
import { Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import ScrollToTop from "components/scrollToTop/scrollToTop";

interface ImageProps {
    height: number;
    width: number;
    src: any;
}

interface NewsProps {
    id: number;
    leadImage: ImageProps;
    title: string;
    publishedDate: string;
    description: string;
    additionalDescription?: any;
    bottomImages: Array<ImageProps>;
}

const NewsPage = () => {
    const isMobileOrTablet = useMediaQuery({ query: "(max-width: 1100px)" });

    const newsList: Array<NewsProps> = [
        {
            id: 1,
            leadImage: {
                height: 201,
                width: 151,
                src: ScaleLeadImage_1,
            },
            title: t(Res.NewsTitle_1),
            publishedDate: `${t(Res.Published)} 29.11.2023.`,
            description: t(Res.NewsDescription_1),
            bottomImages: [
                {
                    height: 180,
                    width: 250,
                    src: RavaBottomImage_1_1,
                },
            ],
        },
        {
            id: 2,
            leadImage: {
                height: 151,
                width: 201,
                src: LeadImage_2,
            },
            title: t(Res.NewsTitle_2),
            publishedDate: `${t(Res.Published)} 28.10.2023.`,
            description: t(Res.NewsDescription_2),
            bottomImages: [],
            additionalDescription: (
                <p className="news-page__description">
                    {`${t(Res.ForMoreDetailsClick)} `}
                    <a
                        href="https://dubrovacki.slobodnadalmacija.hr/dubrovnik/zupanija/konavle/pavo-arbulic-cistoca-i-zelenilo-konavle-prva-je-u-hrvatskoj-uvela-nagradivanje-korisnika-poznati-su-i-detalji-loyalty-programa-1333207"
                        target="_blank"
                        className="news-page__link"
                    >
                        {t(Res.Here).toLocaleLowerCase()}!
                    </a>
                </p>
            ),
        },
    ];

    return (
        <>
            <ScrollToTop />
            <div className={!isMobileOrTablet ? "news-page__container--mobile-or-tablet" : "news-page__container"}>
                <Link
                    to={PATHS.Global.Home}
                    className="news-page__navigation-link"
                >
                    <Image
                        src={Return}
                        height={30}
                        width={33}
                        className="news-page__navigation-link-image"
                    />
                    <div className="news-page__navigation-link-text">{t(Res.GoBack)}</div>
                </Link>
                {newsList.map((x, i) => (
                    <div className="news-page__full-article">
                        <div className="news-page__navigation-link">{x.title}</div>
                        <article className="news-page__image-and-description">
                            <img
                                src={x.leadImage.src}
                                className="news-page__image"
                                height={x.leadImage.height}
                                width={x.leadImage.width}
                            />
                            <p className="news-page__description">{x.description}</p>
                            {x.additionalDescription}
                        </article>
                        {x.bottomImages.map(y => (
                            <Image
                                src={y.src}
                                height={y.height}
                                width={y.width}
                            />
                        ))}
                        <div className="news-page__published-date">{x.publishedDate}</div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default NewsPage;
