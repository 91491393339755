import { StringResources as Res, translate as t } from "utils/language/languageResource";
import { Link } from "react-scroll";
import RavaLogo from "assets/images/landingPageHeader/rava-logo.png";
import CroatiaFlag from "assets/images/language/croatia.png";
import EnglishFlag from "assets/images/language/english.png";
import "./landingPageTopNavbar.scss";
import { Image } from "react-bootstrap";
import { TopbarNavigation } from "utils/constants";
import i18n from "utils/language/languangeClient";
import { SupportedLanguageEnum } from "utils/enums";
import { setLanguage } from "utils/storageActions";
import EuLogo from "assets/images/EU-eng-1.svg";
import EuFondLogo from "assets/images/Europski-fondovi-logo.svg";
import { useMediaQuery } from "react-responsive";

const LandingPageTopNavbar = () => {
    const languageString = i18n.language as keyof typeof SupportedLanguageEnum;
    const languageEnum = SupportedLanguageEnum[languageString];
    const languageFlag = languageEnum === SupportedLanguageEnum.Croatian ? EnglishFlag : CroatiaFlag;

    const hideEuLogo = useMediaQuery({ query: "(max-width: 1100px)" });
    const hideTopNavbar = useMediaQuery({ query: "(max-width: 850px)" });

    const navBarLinkList = [
        {
            to: TopbarNavigation.home,
            title: t(Res.Home),
        },
        {
            to: TopbarNavigation.news,
            title: t(Res.News),
        },
        {
            to: TopbarNavigation.whatIsRava,
            title: t(Res.WhatIsRava) + "?",
        },
        {
            to: TopbarNavigation.howToJoin,
            title: t(Res.HowToJoin) + "?",
        },
        {
            to: TopbarNavigation.ravaBenefits,
            title: t(Res.RavaBenefits),
        },
    ];

    const changeLanguageHandler = () => {
        switch (languageEnum) {
            case SupportedLanguageEnum.Croatian:
                setLanguage(SupportedLanguageEnum.English);
                break;
            case SupportedLanguageEnum.English:
                setLanguage(SupportedLanguageEnum.Croatian);
                break;
            default:
                break;
        }

        window.location.reload();
    };

    return (
        <>
            <nav className="landing-page-top-navbar-container">
                <div className="landing-page-top-navbar-container__inner">
                    <Link
                        className="landing-page-top-navbar__link"
                        to="home"
                    >
                        <Image
                            src={RavaLogo}
                            height={40}
                            width={120}
                        />
                    </Link>
                    <ul className="landing-page-top-navbar__list">
                        {!hideTopNavbar &&
                            navBarLinkList.map(x => (
                                <li className="landing-page-top-navbar__list--element">
                                    <Link
                                        activeClass="landing-page-top-navbar__list--active"
                                        style={{ padding: "10px 15px" }}
                                        to={x.to}
                                        spy={true}
                                        offset={-80}
                                    >
                                        {x.title}
                                    </Link>
                                </li>
                            ))}
                    </ul>
                    <div className="landing-page-top-navbar__language">
                        <Image
                            src={languageFlag}
                            onClick={changeLanguageHandler}
                            style={{ cursor: "pointer" }}
                        />
                        {!hideEuLogo && (
                            <a
                                href="https://www.moberg.hr/swc/"
                                className="landing-page-top-navbar__image-eu-logo"
                                target="_blank"
                            >
                                <EuLogo
                                    height={50}
                                    width={50}
                                    style={{ "margin-right": "10px", "margin-left": "40px" }}
                                />
                                <EuFondLogo
                                    height={50}
                                    width={50}
                                />
                            </a>
                        )}
                    </div>
                </div>
            </nav>
        </>
    );
};

export default LandingPageTopNavbar;
