import classNames from "classnames";
import "./swcCircle.scss";

export interface ISwcCircleProps {
    style?: string;
}

const SwcCircle = (props: ISwcCircleProps) => {
    return <div className={classNames("swc-circle", props.style)} />;
};

export default SwcCircle;
