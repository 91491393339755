import { ErrorMessage, Field, useField } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { StringResources as Res, translate as t } from "utils/language/languageResource";
import { Form } from "react-bootstrap";
import "./swcInputControlFormik.scss";
import classNames from "classnames";

export interface BaseInputControlFormikProps {
    labelTitle: string | any;
    name: string;
    inputType: string;
    htmlFor?: string;
}

export interface ISwcInputControlFormikProps extends BaseInputControlFormikProps {
    id?: string;
    htmlFor?: string;
    placeholder?: string;
    styleDefault?: string;
    styleError?: string;
    styleHeader?: string;
    styleLabel?: string;
    errorInsideInput?: boolean;
    checkboxStyle?: string;
    errorMessageStyle?: string;
}

const SwcInputControlFormik = (props: ISwcInputControlFormikProps) => {
    const [{}, field] = useField(props.name);

    const getField = () => {
        switch (props.inputType) {
            default:
                return (
                    <Field
                        name={props.name}
                        className={`${
                            field.error && field.touched
                                ? "input-error form-control " + props.styleError
                                : "input-default form-control " + props.styleDefault
                        } `}
                        type={props.inputType}
                        label={props.labelTitle}
                        placeholder={
                            field.error && field.touched && props.errorInsideInput ? t(Res.PleaseFillThisField) : ""
                        }
                    />
                );
        }
    };

    return (
        <>
            <Form.Group className={`mb-2 ${props.styleHeader}`}>
                <Form.Label
                    htmlFor={props.htmlFor}
                    className={props.styleLabel}
                >
                    <b>{props.labelTitle}</b>
                </Form.Label>
                <div>
                    {getField()}
                    {!props.errorInsideInput && (
                        <ErrorMessage
                            name={props.name}
                            render={msg => (
                                <div className={classNames("input-error-message", props.errorMessageStyle)}>{msg} </div>
                            )}
                        />
                    )}
                </div>
            </Form.Group>
        </>
    );
};

export default SwcInputControlFormik;
