import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "redux/store";
import { PATHS } from "utils/paths";
import { authEmailVerificationThunk } from "redux/actions/authActions";

const EmailAccountVerification = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { verificationToken } = params;

    useEffect(() => {
        EmailVerificationHandler();
    }, []);

    const EmailVerificationHandler = async () => {
        await dispatch(
            authEmailVerificationThunk({
                verificationToken: verificationToken,
            })
        );

        navigate(PATHS.Global.Home);
    };

    return <></>;
};

export default EmailAccountVerification;
