import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ALL_NEWS, LANDING_PAGE_TEXT, TERMS_AND_CONDITIONS } from "utils/constants";
import { SupportedLanguageEnum } from "utils/enums";
import { getLanguage } from "utils/storageActions";
import { StringResources as Res } from "./languageResource";

i18n.use(initReactI18next).init({
    resources: {
        [SupportedLanguageEnum[SupportedLanguageEnum.English]]: {
            translations: {
                [Res.FirstName]: "First Name",
                [Res.LastName]: "Last Name",
                [Res.Ssn]: "Ssn",
                [Res.Password]: "Password",
                [Res.Email]: "Email",
                [Res.Submit]: "Submit",
                [Res.PleaseEnterAValid]: "Please enter a valid",
                [Res.Register]: "Register",
                [Res.Login]: "Login",
                [Res.SucessNewAccount]:
                    "You have successfuly created a new account. A verification link has been sent to your email address.",
                [Res.DefaultErrorMessage]: "An error occured. Please try again later.",
                [Res.ForgotPassword]: "Forgot password",
                [Res.ForgotPasswordEmailLink]: "A password reset link has been sent to your email address.",
                [Res.NewPassword]: "New Password",
                [Res.LoginSuccess]: "You have successfully logged in.",
                [Res.Customer]: "Customer",
                [Res.Company]: "Company",
                [Res.CompanyName]: "Company name",
                [Res.Logout]: "Logout",
                [Res.PageNotFound]: "Page not found.",
                [Res.GhostStoleThisPage]: "Boo, looks like a ghost stole this page!",
                [Res.ViewLoyaltyPrograms]: "View loyalty programs",
                [Res.Join]: "Join",
                [Res.NoLoyaltyProgramsAvailable]: "No loyalty programs available",
                [Res.Available]: "Available",
                [Res.Joined]: "Joined",
                [Res.Search]: "Search",
                [Res.CreateLoyaltyProgram]: "Create loyalty program",
                [Res.LoyaltyProgramName]: "Loyalty program name",
                [Res.CreateLoyaltyProgramSuccess]: "Loyalty program successfully created",
                [Res.SuccessCreateLoyaltyAccount]:
                    "You have successfully joined loyalty program. A loyalty account has been created for it.",
                [Res.AccountVerified]: "Account successfully verified",
                [Res.NoLoyaltyProgramsJoined]: "No loyalty programs joined",
                [Res.Details]: "Details",
                [Res.Close]: "Close",
                [Res.LoyaltyAccountNumber]: "Loyalty account number",
                [Res.Points]: "Points",
                [Res.Confirm]: "Confirm",
                [Res.MyProfile]: "My profile",
                [Res.RegisterCompany]: "Register company",
                [Res.RegisterCustomer]: "Register customer",
                [Res.ConfirmPassword]: "Confirm password",
                [Res.CompanyNameRequired]: "* Company name is required",
                [Res.EmailRequired]: "* Email is required",
                [Res.InvalidEmail]: "* Email is invalid",
                [Res.InvalidSsn]: "* Ssn is invalid",
                [Res.SsnRequired]: "* Ssn is required",
                [Res.MinPasswordLength]: "* Minimum 8 characters",
                [Res.PasswordRequired]: "* Password is required",
                [Res.PasswordMustMatch]: "* Passwords must match",
                [Res.ConfirmPasswordRequired]: "* Confirm password is required",
                [Res.FirstNameRequired]: "* First name is required",
                [Res.LastNameRequired]: "* Last name is required",
                [Res.Update]: "Update",
                [Res.AccountUpdatedSuccess]: "Account successfully updated",
                [Res.UpdateCustomerDetails]: "Update customer details",
                [Res.CompanyLoyaltyPrograms]: "Loyalty programs",
                [Res.NoLoyaltyProgramsCreated]: "No loyalty programs created",
                [Res.CreateNewApiKey]: "Create API key",
                [Res.ApiKeys]: "API keys",
                [Res.Deactivate]: "Deactivate",
                [Res.SuccessDeactivateApiKey]: "You have successfully deactivated your API key",
                [Res.SuccessGenerateApiKey]: "You have successfully created new API key for your loyalty program",
                [Res.LoyaltyProgramNameRequired]: "* Loyalty program name is required",
                [Res.Customers]: "Customers",
                [Res.InsertAccountNumbers]: "Insert account numbers",
                [Res.CustomerAccountNumber]: "Customer account number",
                [Res.LastActiveDate]: "Last active date",
                [Res.RecyclingYard]: "Recycling yard",
                [Res.EditCustomer]: "Edit customer",
                [Res.AddRemovePoints]: "Add/remove points",
                [Res.DisableEnablePointsCollection]: "Disable/enable points collection",
                [Res.NoAccountNumbersFound]: "No account numbers found",
                [Res.PointsRule]: "Points rule",
                [Res.EditPoints]: "Edit points",
                [Res.Products]: "Products",
                [Res.AmountInGrams]: "Amount in grams",
                [Res.NoProductsFound]: "No products found",
                [Res.SuccessUpdateProductRule]: "You have successfully updated your product rule",
                [Res.AdministrationRecyclingYards]: "Administration of recycling yards",
                [Res.Address]: "Address",
                [Res.City]: "City",
                [Res.WorkingHoursMondayFriday]: "Working hours (monday/friday)",
                [Res.WorkingHoursSaturday]: "Working hours (saturday)",
                [Res.WorkingHoursSundayHolidays]: "Working hours (sunday/holidays)",
                [Res.Telephone]: "Telephone",
                [Res.NoTransactionsFound]: "No transactions found",
                [Res.SuccessCreateNewLocation]: "You have successfully created new location",
                [Res.Transactions]: "Transactions",
                [Res.PleaseSelectProduct]: "Please select a product",
                [Res.Date]: "Date",
                [Res.LocationCount]: "Locations count",
                [Res.ProductCount]: "Products count",
                [Res.CustomerCount]: "Customers count",
                [Res.TransactionCount]: "Transactions count",
                [Res.AmountInKilograms]: "Amount in kilograms",
                [Res.LocationAddress]: "Location address",
                [Res.ProductType]: "Product type",
                [Res.TransactionId]: "Transaction ID",
                [Res.TransactionTime]: "Transaction time",
                [Res.CreateProduct]: "Create product",
                [Res.ProductName]: "Product name",
                [Res.ProductNameRequired]: "Product name is required",
                [Res.MinProductAmount]: "Minimum amount is 1",
                [Res.ProductAmountRequired]: "Product amount is required",
                [Res.MinPointAmount]: "Minimum point is 1",
                [Res.PointAmountRequired]: "Point amount is required",
                [Res.ProductAmount]: "Product amount",
                [Res.PointAmount]: "Point amount",
                [Res.SuccessCreateNewProduct]: "You have successfully created a new product",
                [Res.AddUser]: "Add user",
                [Res.CreateCompany]: "Create company",
                [Res.VerificationTime]: "Verification time",
                [Res.ContactPersonRequired]: "Contact person is required",
                [Res.ContactPersonEmailRequired]: "Contact person email is required",
                [Res.PhoneNumberRequired]: "Phone number is required",
                [Res.AddressRequired]: "Address is required",
                [Res.ContactPerson]: "Contact person",
                [Res.ContactPersonEmail]: "Contact person email",
                [Res.SuccessCreateNewCompany]: "You have successfully created new company",
                [Res.PleaseSelectLoyaltyProgramGroup]: "Please select loyalty program group",
                [Res.CreateCompanyUser]: "Create company user",
                [Res.SuccessCreateCompanyUser]: "You have successfully created new user",
                [Res.Coupons]: "Coupons",
                [Res.CouponStarts]: "Coupon starts",
                [Res.CouponEnds]: "Coupon ends",
                [Res.CouponUsage]: "Coupon usage",
                [Res.Value]: "Value",
                [Res.Used]: "Used",
                [Res.CouponName]: "Coupon name",
                [Res.NoCouponsFound]: "No coupons found",
                [Res.CreateCoupon]: "Create coupon",
                [Res.MinCouponAmount]: "Minimum coupon amount is 1",
                [Res.CouponAmountRequired]: "Coupon amount is required",
                [Res.CouponNameRequired]: "Coupon name is required",
                [Res.MinCouponPoints]: "Minimum coupon points is 1",
                [Res.CouponPointsRequired]: "Coupon points is required",
                [Res.CouponStartRequired]: "Coupon start date is required",
                [Res.MinCouponNumberOfUses]: "Minimum coupon number of uses is 1",
                [Res.CouponNumberOfUsesRequired]: "Coupon number of uses is required",
                [Res.CouponEndRequired]: "Coupon end date is required",
                [Res.CouponAmount]: "Coupon amount",
                [Res.CouponPoints]: "Coupon points",
                [Res.CouponNumberOfUses]: "Coupon number of uses",
                [Res.SuccessCreateCoupon]: "You have successfully create new coupon",
                [Res.CouponInformationRequired]: "Coupon information is required",
                [Res.CouponInformation]: "Coupon information",
                [Res.SuccessDeleteCoupon]: "You have successfully deleted a coupon",
                [Res.Rava]: "Rava",
                [Res.Home]: "Home",
                [Res.WhatIsRava]: "What is Rava",
                [Res.HowToJoin]: "How to join",
                [Res.RavaBenefits]: "Rava benefits",
                [Res.RavaSmartWasteSorting]: "Rava - Smart waste sorting",
                [Res.LandingPageHomeDescription]: LANDING_PAGE_TEXT.homeDescription.en,
                [Res.WhatIsItAbout]: "What is it about",
                [Res.WhatIsRava_1]: LANDING_PAGE_TEXT.whatIsRava_1.en,
                [Res.WhatIsRava_2]: LANDING_PAGE_TEXT.whatIsRava_2.en,
                [Res.WhyJoin]: "Why join",
                [Res.WhyJoinDescription]: LANDING_PAGE_TEXT.whyJoinDescription.en,
                [Res.ReduceWaste]: "Reduce waste",
                [Res.ReduceWasteDescription]: LANDING_PAGE_TEXT.reduceWasteDescription.en,
                [Res.ReduceCost]: "Reduce your cost",
                [Res.ReduceCostDescription]: LANDING_PAGE_TEXT.reduceCostDescription.en,
                [Res.MaximazeRecovery]: "Maximizing the recovery of valuable materials",
                [Res.MaximazeRecoveryDescription]: LANDING_PAGE_TEXT.maximazeRecoveryDescription.en,
                [Res.ReduceCarbonFootprint]: "Reduce carbon footprint",
                [Res.ReduceCarbonFootprintDescription]: LANDING_PAGE_TEXT.reduceCarbonFootprintDescription.en,
                [Res.WhatAreRavaBenefits]: "What are benefits of Rava",
                [Res.RavaBenefitsDescription_1]: LANDING_PAGE_TEXT.ravaBenefitDescription_1.en,
                [Res.RavaBenefitsDescription_2]: LANDING_PAGE_TEXT.ravaBenefitDescription_2.en,
                [Res.RavaBenefitsDescription_3]: LANDING_PAGE_TEXT.ravaBenefitDescription_3.en,
                [Res.DownloadRavaApp]: "Download Rava app from",
                [Res.GooglePlayStoreAndroid]: "Google Play Store for Android",
                [Res.Or]: "Or",
                [Res.AppleStoreIOS]: "Apple Store for iOS",
                [Res.RavaBenefitsTitle_2]: LANDING_PAGE_TEXT.ravaBenefitTitle_2.en,
                [Res.RavaBenefitsTitle_3]: LANDING_PAGE_TEXT.ravaBenefitTitle_3.en,
                [Res.RavaBenefitsTitle_4]: LANDING_PAGE_TEXT.ravaBenefitTitle_4.en,
                [Res.AllRightsReserved]: "All rights reserved",
                [Res.SuccessChangeForgottenPassword]: "You have successfully changed your password",
                [Res.PleaseFillThisField]: "Please fill this field",
                [Res.ChangeForgottenPassword]: "Change forgotten password",
                [Res.ContactEmail]: "Contact email",
                [Res.ReadPrivacyPolicy]: "Read privacy policy",
                [Res.Here]: "Here",
                [Res.ChangesToTerms]: "Changes to terms",
                [Res.TermsAndConditionsChangesToTerms]: TERMS_AND_CONDITIONS.changesToTerms.en,
                [Res.AccountRegistration]: "Account registration",
                [Res.TermsAndConditionsAccountRegistration]: TERMS_AND_CONDITIONS.accountRegistration.en,
                [Res.AccountSecurity]: "Account security",
                [Res.TermsAndConditionsAccountRegistrationAccountSecurity]:
                    TERMS_AND_CONDITIONS.registrationAccountSecurity.en,
                [Res.TerminationOfAccount]: "Termination of account",
                [Res.TermsAndConditionsAccountRegistrationTerminationOfAccount]:
                    TERMS_AND_CONDITIONS.registrationTerminationOfAccount.en,
                [Res.DataAccuracy]: "Data accuracy",
                [Res.TermsAndConditionsAccountRegistrationDataAccuracy]:
                    TERMS_AND_CONDITIONS.registrationDataAccuracy.en,
                [Res.AcceptanceOfTerms]: "Acceptance of terms",
                [Res.TermsAndConditionsAcceptanceOfTerms]: TERMS_AND_CONDITIONS.acceptanceOfTerms.en,
                [Res.TermsAndConditions]: "Terms & Conditions",
                [Res.TermsAndConditionsTopDescription]: TERMS_AND_CONDITIONS.topDescription.en,
                [Res.News]: "News",
                [Res.GoBack]: "Go back",
                [Res.Published]: "Published",
                [Res.NewsTitle_1]: ALL_NEWS.news_1.title.en,
                [Res.NewsDescription_1]: ALL_NEWS.news_1.description.en,
                [Res.ForMoreNewsClick]: "For more news, click",
                [Res.NewsTitle_2]: ALL_NEWS.news_2.title.en,
                [Res.NewsDescription_2]: ALL_NEWS.news_2.description.en,
                [Res.ForMoreDetailsClick]: "For more details, click",
            },
        },
        [SupportedLanguageEnum[SupportedLanguageEnum.Croatian]]: {
            translations: {
                [Res.FirstName]: "Ime",
                [Res.LastName]: "Prezime",
                [Res.Ssn]: "OIB",
                [Res.Password]: "Šifra",
                [Res.Email]: "Email",
                [Res.Submit]: "Potvrdi",
                [Res.PleaseEnterAValid]: "Molimo unesite valjano",
                [Res.Register]: "Registracija",
                [Res.Login]: "Prijava",
                [Res.SucessNewAccount]:
                    "Uspješno ste napravili novi račun. Link za potvrdu računa je poslan na vašu e-mail adresu.",
                [Res.DefaultErrorMessage]: "Dogodila se greška. Molimo pokušajte ponovno kasnije.",
                [Res.ForgotPassword]: "Zaboravio šifru",
                [Res.ForgotPasswordEmailLink]: "Link za resetiranje šifre je poslan na vašu email adresu.",
                [Res.NewPassword]: "Nova Šifra",
                [Res.LoginSuccess]: "Uspješno ste se prijavili.",
                [Res.Customer]: "Korisnik",
                [Res.Company]: "Tvrtka",
                [Res.CompanyName]: "Naziv tvrtke",
                [Res.Logout]: "Odjava",
                [Res.PageNotFound]: "Stranica nije pronađena",
                [Res.GhostStoleThisPage]: "Boo, čini se da je duh ukrao ovu stranicu!",
                [Res.ViewLoyaltyPrograms]: "Pregledaj loyalty programe",
                [Res.Join]: "Pridruži",
                [Res.NoLoyaltyProgramsAvailable]: "Nema dostupnih loyalty programa",
                [Res.Available]: "Dostupno",
                [Res.Joined]: "Pridruženo",
                [Res.Search]: "Traži",
                [Res.CreateLoyaltyProgram]: "Stvori loyalty program",
                [Res.LoyaltyProgramName]: "Naziv loyalty programa",
                [Res.CreateLoyaltyProgramSuccess]: "Uspješno kreiran loyalty program",
                [Res.SuccessCreateLoyaltyAccount]:
                    "Uspješno ste se uključili u loyalty program. Napravljen je loyalty račun za loyalty program.",
                [Res.AccountVerified]: "Račun uspješno potvrđen",
                [Res.NoLoyaltyProgramsJoined]: "Niste ste pridružili niti jednom loyalti programu",
                [Res.Details]: "Detalji",
                [Res.Close]: "Zatvori",
                [Res.LoyaltyAccountNumber]: "Broj loyalty računa",
                [Res.Points]: "Bodovi",
                [Res.Confirm]: "Potvrdi",
                [Res.MyProfile]: "Moj profil",
                [Res.RegisterCompany]: "Registracija tvrtke",
                [Res.RegisterCustomer]: "Registracija klijenta",
                [Res.ConfirmPassword]: "Potvrda lozinke",
                [Res.CompanyNameRequired]: "* Naziv tvrtke je obavezan",
                [Res.EmailRequired]: "* Email je obavezan",
                [Res.InvalidEmail]: "* Neispravan email",
                [Res.InvalidSsn]: "* Neispravan OIB",
                [Res.SsnRequired]: "* OIB je obavezan",
                [Res.MinPasswordLength]: "* Minimalno 8 znakova",
                [Res.PasswordRequired]: "* Lozinka je obavezna",
                [Res.PasswordMustMatch]: "* Lozinke se moraju podudarati",
                [Res.ConfirmPasswordRequired]: "* Potvrda lozinke je obavezna",
                [Res.FirstNameRequired]: "* Ime je obavezno",
                [Res.LastNameRequired]: "* Prezime je obavezno",
                [Res.Update]: "Ažuriraj",
                [Res.AccountUpdatedSuccess]: "Račun uspješno ažuriran",
                [Res.UpdateCustomerDetails]: "Ažuriranje podatka korisnika",
                [Res.CompanyLoyaltyPrograms]: "Lojalni programi",
                [Res.NoLoyaltyProgramsCreated]: "Niste stvorili niti jedan lojalni program",
                [Res.CreateNewApiKey]: "Stvori API ključ",
                [Res.ApiKeys]: "API ključevi",
                [Res.Deactivate]: "Deaktiviraj",
                [Res.SuccessDeactivateApiKey]: "Uspješno ste deaktivirali Vaš API ključ",
                [Res.SuccessGenerateApiKey]: "Uspješno ste stvorili novi API ključ za Vaš program lojalnosti",
                [Res.LoyaltyProgramNameRequired]: "* Ime programa je obavezno",
                [Res.Customers]: "Korisnici",
                [Res.InsertAccountNumbers]: "Unesite korisničke račune",
                [Res.CustomerAccountNumber]: "Broj računa korisnika",
                [Res.LastActiveDate]: "Datum zadnje aktivnosti",
                [Res.RecyclingYard]: "Reciklažno dvorište",
                [Res.EditCustomer]: "Uredi korisnika",
                [Res.AddRemovePoints]: "Dodaj/oduzmi bodove",
                [Res.DisableEnablePointsCollection]: "Onemogući/omogući skupljanje bodova",
                [Res.NoAccountNumbersFound]: "Nije pronađen niti jedan broj računa",
                [Res.PointsRule]: "Pravila bodovanja",
                [Res.EditPoints]: "Uredi bodovanje",
                [Res.Products]: "Proizvodi",
                [Res.AmountInGrams]: "Količina u gramima",
                [Res.NoProductsFound]: "Nije pronađen niti jedan proizvod",
                [Res.SuccessUpdateProductRule]: "Uspješno ste ažurirali pravila bodovanja proizvoda",
                [Res.AdministrationRecyclingYards]: "Administracija reciklažnih dvorišta",
                [Res.Address]: "Adresa",
                [Res.City]: "Grad",
                [Res.WorkingHoursMondayFriday]: "Radno vrijeme (pon/petak)",
                [Res.WorkingHoursSaturday]: "Radno vrijeme (sub)",
                [Res.WorkingHoursSundayHolidays]: "Radno vrijeme (ned/praznik)",
                [Res.Telephone]: "Telefon",
                [Res.NoTransactionsFound]: "Nije pronađena niti jedna transakcija",
                [Res.SuccessCreateNewLocation]: "Uspješno ste stvorili novu lokaciju",
                [Res.Transactions]: "Transakcije",
                [Res.PleaseSelectProduct]: "Molimo odaberite proizvod",
                [Res.Date]: "Datum",
                [Res.LocationCount]: "Broj lokacija",
                [Res.ProductCount]: "Broj proizvoda",
                [Res.CustomerCount]: "broj kupca",
                [Res.TransactionCount]: "Broj transakcija",
                [Res.AmountInKilograms]: "Količina u kilogramima",
                [Res.LocationAddress]: "Reciklažno dvorište",
                [Res.ProductType]: "Vrsta otpada",
                [Res.TransactionId]: "ID transakcije",
                [Res.TransactionTime]: "Vrijeme transakcije",
                [Res.CreateProduct]: "Stvori proizvod",
                [Res.ProductName]: "Ime proizvoda",
                [Res.ProductNameRequired]: "Ime proizvoda je obavezno",
                [Res.MinProductAmount]: "Minimalni iznos je 1",
                [Res.ProductAmountRequired]: "Količina proizvoda je obavezna",
                [Res.MinPointAmount]: "Minimalni bod je 1",
                [Res.PointAmountRequired]: "Iznos bodova je obavezna",
                [Res.ProductAmount]: "Količina proizvoda",
                [Res.PointAmount]: "Iznos bodova",
                [Res.SuccessCreateNewProduct]: "Uspješno ste stvorili novi proizvod",
                [Res.AddUser]: "Dodaj korisnika",
                [Res.CreateCompany]: "Stvori tvrtku",
                [Res.VerificationTime]: "Vrijeme verifikacije",
                [Res.ContactPersonRequired]: "Kontakt osobe je obavezno",
                [Res.ContactPersonEmailRequired]: "Email kontakta osobe je obavezno",
                [Res.PhoneNumberRequired]: "Broj telefona je obavezno",
                [Res.AddressRequired]: "Adresa je obavezna",
                [Res.ContactPerson]: "Kontakt osoba",
                [Res.ContactPersonEmail]: "Email kontakta osobe",
                [Res.SuccessCreateNewCompany]: "Uspješno ste stvorili novu tvrtku",
                [Res.PleaseSelectLoyaltyProgramGroup]: "Molimo odaberite grupu programa odajnosti",
                [Res.CreateCompanyUser]: "Stvaranje korisnika tvrtke",
                [Res.SuccessCreateCompanyUser]: "Uspješno ste stvorili novog korisnika tvrtke",
                [Res.Coupons]: "Kuponi",
                [Res.CouponStarts]: "Kupon počinje",
                [Res.CouponEnds]: "Kupon završava",
                [Res.CouponUsage]: "Iskoristivost kupona",
                [Res.Value]: "Vrijednost",
                [Res.Used]: "Iskorišteno",
                [Res.CouponName]: "Ime kupona",
                [Res.NoCouponsFound]: "Nije pronađen niti jedan kupon",
                [Res.CreateCoupon]: "Stvorite kupon",
                [Res.MinCouponAmount]: "Minimalni iznos kupona je 1",
                [Res.CouponAmountRequired]: "Iznos kupona je obavezna",
                [Res.CouponNameRequired]: "Ime kupona je obavezno",
                [Res.MinCouponPoints]: "Minimalni broj bodova kupona je 1",
                [Res.CouponPointsRequired]: "Broj bodova kupona je obavezna",
                [Res.CouponStartRequired]: "Početak trajanja kupona je obavezna",
                [Res.MinCouponNumberOfUses]: "Minimalni broj korištenja kupona je 1",
                [Res.CouponNumberOfUsesRequired]: "Broj korištenja kupona je obavezna",
                [Res.CouponEndRequired]: "Kraj trajanja kupona je obavezna",
                [Res.CouponAmount]: "Iznos kupona",
                [Res.CouponPoints]: "Iznos bodova kupona",
                [Res.CouponNumberOfUses]: "Broj korištenja kupona",
                [Res.SuccessCreateCoupon]: "Uspješno ste stvorili novi kupon",
                [Res.CouponInformationRequired]: "Informacija o kuponu je obavezna",
                [Res.CouponInformation]: "Informacija o kuponu",
                [Res.SuccessDeleteCoupon]: "Uspješno ste izbrisali kupon",
                [Res.Rava]: "Rava",
                [Res.Home]: "Početak",
                [Res.WhatIsRava]: "Što je to Rava",
                [Res.HowToJoin]: "Kako se pridružiti",
                [Res.RavaBenefits]: "Pogodnosti Rave",
                [Res.RavaSmartWasteSorting]: "Rava - Pametno razvrstavanje otpada",
                [Res.LandingPageHomeDescription]: LANDING_PAGE_TEXT.homeDescription.hr,
                [Res.WhatIsItAbout]: "O čemu se radi",
                [Res.WhatIsRava_1]: LANDING_PAGE_TEXT.whatIsRava_1.hr,
                [Res.WhatIsRava_2]: LANDING_PAGE_TEXT.whatIsRava_2.hr,
                [Res.WhyJoin]: "Zašto se pridružiti",
                [Res.WhyJoinDescription]: LANDING_PAGE_TEXT.whyJoinDescription.hr,
                [Res.ReduceWaste]: "Smanjuje otpad",
                [Res.ReduceWasteDescription]: LANDING_PAGE_TEXT.reduceWasteDescription.hr,
                [Res.ReduceCost]: "Smanjujete svoje troškove",
                [Res.ReduceCostDescription]: LANDING_PAGE_TEXT.reduceCostDescription.hr,
                [Res.MaximazeRecovery]: "Maksimiziranje oporabe vrijednih materijala",
                [Res.MaximazeRecoveryDescription]: LANDING_PAGE_TEXT.maximazeRecoveryDescription.hr,
                [Res.ReduceCarbonFootprint]: "Smanjuje ugljični otisak",
                [Res.ReduceCarbonFootprintDescription]: LANDING_PAGE_TEXT.reduceCarbonFootprintDescription.hr,
                [Res.WhatAreRavaBenefits]: "Koje su pogodnosti Rave",
                [Res.RavaBenefitsDescription_1]: LANDING_PAGE_TEXT.ravaBenefitDescription_1.hr,
                [Res.RavaBenefitsDescription_2]: LANDING_PAGE_TEXT.ravaBenefitDescription_2.hr,
                [Res.RavaBenefitsDescription_3]: LANDING_PAGE_TEXT.ravaBenefitDescription_3.hr,
                [Res.DownloadRavaApp]: "Preuzmite aplikaciju Rava s",
                [Res.GooglePlayStoreAndroid]: "Google Play Store za Android",
                [Res.Or]: "Ili",
                [Res.AppleStoreIOS]: "Apple Store za iOS",
                [Res.RavaBenefitsTitle_2]: LANDING_PAGE_TEXT.ravaBenefitTitle_2.hr,
                [Res.RavaBenefitsTitle_3]: LANDING_PAGE_TEXT.ravaBenefitTitle_3.hr,
                [Res.RavaBenefitsTitle_4]: LANDING_PAGE_TEXT.ravaBenefitTitle_4.hr,
                [Res.AllRightsReserved]: "Sva prava pridržana",
                [Res.SuccessChangeForgottenPassword]: "Uspješno ste promijenili šifru",
                [Res.PleaseFillThisField]: "Molimo, ispunite ovo polje",
                [Res.ChangeForgottenPassword]: "Izmjenite zaboravljenu lozinku",
                [Res.ContactEmail]: "Email za kontakt",
                [Res.ReadPrivacyPolicy]: "Pročitajte pravila o privatnosti",
                [Res.Here]: "ovdje",
                [Res.ChangesToTerms]: "Promjene uvjeta",
                [Res.TermsAndConditionsChangesToTerms]: TERMS_AND_CONDITIONS.changesToTerms.hr,
                [Res.AccountRegistration]: "Registriranje računa",
                [Res.TermsAndConditionsAccountRegistration]: TERMS_AND_CONDITIONS.accountRegistration.hr,
                [Res.AccountSecurity]: "Sigurnost računa",
                [Res.TermsAndConditionsAccountRegistrationAccountSecurity]:
                    TERMS_AND_CONDITIONS.registrationAccountSecurity.hr,
                [Res.TerminationOfAccount]: "Ukidanje računa",
                [Res.TermsAndConditionsAccountRegistrationTerminationOfAccount]:
                    TERMS_AND_CONDITIONS.registrationTerminationOfAccount.hr,
                [Res.DataAccuracy]: "Točnost podataka",
                [Res.TermsAndConditionsAccountRegistrationDataAccuracy]:
                    TERMS_AND_CONDITIONS.registrationDataAccuracy.hr,
                [Res.AcceptanceOfTerms]: "Prihvaćanje uvjeta",
                [Res.TermsAndConditionsAcceptanceOfTerms]: TERMS_AND_CONDITIONS.acceptanceOfTerms.hr,
                [Res.TermsAndConditions]: "Odredbe i uvjeti",
                [Res.TermsAndConditionsTopDescription]: TERMS_AND_CONDITIONS.topDescription.hr,
                [Res.News]: "Novosti",
                [Res.GoBack]: "Nazad",
                [Res.Published]: "Objavljeno",
                [Res.NewsTitle_1]: ALL_NEWS.news_1.title.hr,
                [Res.NewsDescription_1]: ALL_NEWS.news_1.description.hr,
                [Res.ForMoreNewsClick]: "Za više novosti, pritisnite",
                [Res.NewsTitle_2]: ALL_NEWS.news_2.title.hr,
                [Res.NewsDescription_2]: ALL_NEWS.news_2.description.hr,
                [Res.ForMoreDetailsClick]: "Za više detalja, pritisnite",
            },
        },
    },
    lng: SupportedLanguageEnum[SupportedLanguageEnum.Croatian],
    fallbackLng: SupportedLanguageEnum[SupportedLanguageEnum.Croatian],
    debug: true,

    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false,
    },
});

const language = getLanguage();

if (language) {
    i18n.changeLanguage(SupportedLanguageEnum[language.language as SupportedLanguageEnum]);
}

export default i18n;
