import { StringResources as Res, translate as t } from "utils/language/languageResource";
import { Image } from "react-bootstrap";
import ImageOne from "assets/images/landingPageWhatIsEkolure/image-1.jpg";
import ImageTwo from "assets/images/landingPageWhatIsEkolure/image-2.jpg";
import ImageThree from "assets/images/landingPageWhatIsEkolure/image-3.jpg";
import "./landingPageWhatIsEkolure.scss";
import { TopbarNavigation } from "utils/constants";
import { useMediaQuery } from "react-responsive";

const LandingPageWhatIsEkolure = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 860px)" });

    return (
        <section
            className="landing-page-what-is-ekolure"
            id={TopbarNavigation.whatIsRava}
        >
            <div className="landing-page-what-is-ekolure-container">
                <div className="landing-page-what-is-ekolure__text-and-images">
                    <div className="landing-page-what-is-ekolure__text-and-images--left">
                        {isMobile && (
                            <div className="landing-page-what-is-ekolure__text-and-images--right-image-one">
                                <Image src={ImageOne} />
                            </div>
                        )}
                        <h2 className="landing-page-what-is-ekolure__text-and-images--left-title">
                            {t(Res.WhatIsItAbout)}?
                        </h2>
                        <p className="landing-page-what-is-ekolure__text-and-images--left-description">
                            {t(Res.WhatIsRava_1)}
                        </p>
                        <p className="landing-page-what-is-ekolure__text-and-images--left-description">
                            {t(Res.WhatIsRava_2)}
                        </p>
                    </div>
                    <div className="landing-page-what-is-ekolure__text-and-images--right">
                        <div className="landing-page-what-is-ekolure__text-and-images--right-inner">
                            <div className="landing-page-what-is-ekolure__text-and-images--right-inner-images">
                                {!isMobile && (
                                    <div className="landing-page-what-is-ekolure__text-and-images--right-image-one">
                                        <Image src={ImageOne} />
                                    </div>
                                )}
                                <div className="landing-page-what-is-ekolure__text-and-images--right-image-two">
                                    <Image src={ImageTwo} />
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div className="landing-page-what-is-ekolure__text-and-images--right-image-three">
                                    <Image src={ImageThree} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LandingPageWhatIsEkolure;
