import { createSlice } from "@reduxjs/toolkit";
import { LoadingActionModel } from "redux/models/authModels";

export interface AuthReducerState {
    error?: string;
    loadingActions: Array<LoadingActionModel>;
}

const initialState: AuthReducerState = {
    error: "",
    loadingActions: [],
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        addLoaderAction(state, action) {
            state.loadingActions.push(action.payload);
        },
        removeLoaderAction(state, action) {
            state.loadingActions = state.loadingActions.filter(x => x.requestId != action.payload);
        },
    },
    extraReducers: builder => {},
});

const { reducer } = authSlice;
export const { addLoaderAction, removeLoaderAction } = authSlice.actions;
export default reducer;
