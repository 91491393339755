import { StringResources as Res, translate as t } from "utils/language/languageResource";
import { Image } from "react-bootstrap";
import { Link } from "react-scroll";
import RavaLogo from "assets/images/landingPageHeader/rava-logo.png";
import "./landingPageFooter.scss";
import { TopbarNavigation } from "utils/constants";
import EuLogo from "assets/images/EU-eng-1.svg";
import EuFondLogo from "assets/images/Europski-fondovi-logo.svg";
import { useMediaQuery } from "react-responsive";
import { Link as LinkPage } from "react-router-dom";
import { PATHS } from "utils/paths";

const LandingPageFooter = () => {
    const hideEuLogo = useMediaQuery({ query: "(max-width: 1100px)" });

    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    return (
        <div className="landing-page-footer">
            <div style={{ width: "100%", maxWidth: "1220px", margin: "0 auto", padding: "0px 30px" }}>
                <div className="landing-page-footer-container">
                    {!hideEuLogo ? (
                        <Link
                            to={TopbarNavigation.home}
                            offset={-80}
                            className="landing-page-footer__logo-link"
                        >
                            <Image
                                src={RavaLogo}
                                height={40}
                                width={120}
                            />
                        </Link>
                    ) : (
                        <div className="landing-page-footer__logo-link">
                            <a
                                href="https://www.moberg.hr/swc/"
                                className="landing-page-footer__image-eu-logo"
                                target="_blank"
                            >
                                <EuLogo
                                    height={70}
                                    width={70}
                                    style={{ "margin-right": "10px" }}
                                />
                                <EuFondLogo
                                    height={70}
                                    width={70}
                                />
                            </a>
                        </div>
                    )}
                    <p className="landing-page-footer__copyright">
                        © {getCurrentYear()} -{" "}
                        <span className="landing-page-footer__copyright-span">{t(Res.Rava)}</span>{" "}
                        {t(Res.AllRightsReserved).toLocaleLowerCase()}
                    </p>
                    <div>
                        <p className="landing-page-footer__copyright">{t(Res.ContactEmail)}: rava@rava.hr</p>
                        <p className="landing-page-footer__copyright">
                            {`${t(Res.ReadPrivacyPolicy)} `}
                            <LinkPage
                                className="landing-page-footer__privacy-policy-link"
                                to={PATHS.Global.TermsAndConditions}
                            >
                                {t(Res.Here).toLowerCase()}
                            </LinkPage>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPageFooter;
