import jwt_decode from "jwt-decode";
import { SupportedLanguageEnum } from "./enums";

export function getUserLocalStorageData() {
    const stored = localStorage.getItem("user");
    if (!stored) {
        return undefined;
    }
    return JSON.parse(stored);
}

export function setUserLocalStorageData(data: any) {
    localStorage.setItem(
        "user",
        JSON.stringify({
            data,
        })
    );
}

export function removeUserLocalStorageData() {
    localStorage.removeItem("user");
}

export function getUserType(bearerToken: string) {
    var decodedBearerToken: any = jwt_decode(bearerToken);
    return decodedBearerToken.role;
}

export function getLanguage() {
    const stored = localStorage.getItem("language");
    if (!stored) {
        return undefined;
    }
    return JSON.parse(stored);
}

export function setLanguage(language: SupportedLanguageEnum) {
    localStorage.setItem(
        "language",
        JSON.stringify({
            language,
        })
    );
}
