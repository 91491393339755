import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import { useDispatch } from "react-redux";
import { loaderMiddleware } from "redux/storeActionMiddleware";

export const store = configureStore({
    reducer: combineReducers({
        auth: authReducer,
    }),
    middleware: () => getDefaultMiddleware().prepend(loaderMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
