import ScrollToTop from "components/scrollToTop/scrollToTop";
import { StringResources as Res, translate as t } from "utils/language/languageResource";
import "./termsAndConditions.scss";

const TermsAndConditions = () => {
    const termsAndConditionsFields = [
        {
            sectionTitle: t(Res.ChangesToTerms),
            text: t(Res.TermsAndConditionsChangesToTerms),
        },
        {
            sectionTitle: t(Res.AccountRegistration),
            text: t(Res.TermsAndConditionsAccountRegistration),
            subsection: [
                {
                    title: t(Res.AccountSecurity),
                    text: t(Res.TermsAndConditionsAccountRegistrationAccountSecurity),
                },
                {
                    title: t(Res.DataAccuracy),
                    text: t(Res.TermsAndConditionsAccountRegistrationDataAccuracy),
                },
                {
                    title: t(Res.TerminationOfAccount),
                    text: t(Res.TermsAndConditionsAccountRegistrationTerminationOfAccount),
                },
            ],
        },
        {
            sectionTitle: t(Res.AcceptanceOfTerms),
            text: t(Res.TermsAndConditionsAcceptanceOfTerms),
        },
    ];

    return (
        <>
            <ScrollToTop />
            <div className="terms-and-conditions__container">
                <div className="terms-and-conditions__title-text">{t(Res.TermsAndConditions)}</div>
                <div className="terms-and-conditions__title-description-text">
                    {t(Res.TermsAndConditionsTopDescription)}
                </div>
                {termsAndConditionsFields.map((x, indexTitle) => (
                    <div key={indexTitle}>
                        <div className="terms-and-conditions__section-title-text">
                            {indexTitle + 1}. {x.sectionTitle}
                        </div>
                        <div className="terms-and-conditions__section-description-text">{x.text}</div>
                        {x.subsection?.map((y, indexSubsection) => (
                            <div key={indexSubsection}>
                                <div className="terms-and-conditions__subsection-title-text">
                                    {indexTitle + 1}.{indexSubsection + 1}. {y.title}
                                </div>
                                <div className="terms-and-conditions__subsection-description-text">{y.text}</div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    );
};

export default TermsAndConditions;
