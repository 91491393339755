import { StringResources as Res, translate as t } from "utils/language/languageResource";
import { TopbarNavigation } from "utils/constants";
import "./landingPageNews.scss";
import { Link } from "react-router-dom";
import ScaleLeadImage_1 from "assets/images/news/1/scale.png";
import { PATHS } from "utils/paths";

const LandingPageNews = () => {
    return (
        <section
            className="landing-page-news"
            id={TopbarNavigation.news}
        >
            <div className="landing-page-news-container">
                <div className="landing-page-news__text-and-images-container">
                    <div className="landing-page-news__text-and-images--title">{t(Res.NewsTitle_1)}</div>
                    <article className="landing-page-news__text-and-images">
                        <img
                            src={ScaleLeadImage_1}
                            className="landing-page-news__image"
                            height={201}
                            width={151}
                        />
                        <p className="landing-page-news__description">{`${t(Res.NewsDescription_1)}\n\n`}</p>
                        <p className="landing-page-news__description">
                            {`${t(Res.ForMoreNewsClick)} `}
                            <Link
                                className="landing-page-news__link"
                                to={PATHS.Global.News}
                            >
                                {t(Res.Here).toLocaleLowerCase()}!
                            </Link>
                        </p>
                    </article>
                </div>
            </div>
        </section>
    );
};

export default LandingPageNews;
