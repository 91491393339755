import apiClient from "utils/axios/apiClient";
import { SwcAxiosRequestConfig } from "utils/axios/apiModels";
import { devConsoleLog } from "utils/helperFunctions";
import { RequestConfig } from "redux/services/servicesModels";
import { getUserLocalStorageData } from "utils/storageActions";
const FileDownload = require("js-file-download");

export const getToken = () => {
    const storage = getUserLocalStorageData();

    return storage?.data?.bearerToken;
};

export const executeDownloadGetRequest = async (url: string, params?: any, requestConfig?: RequestConfig) => {
    const config: SwcAxiosRequestConfig = {
        params: params,
        headers: { Authorization: `Bearer ${getToken()}` },
        responseType: "blob",
        successMessage: requestConfig?.successMessage,
        errorMessage: requestConfig?.errorMessage,
        useBackendErrorMessage: requestConfig?.useBackendErrorMessage,
    };
    await apiClient.get(url, config).then(response => {
        let headerLine = response.headers["content-disposition"].replaceAll('"', "");
        let startFileNameIndex = headerLine.indexOf("filename=") + "filename=".length;
        let endFileNameIndex = headerLine.indexOf(";", startFileNameIndex);
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        //@ts-ignore
        FileDownload(response.data, filename != "" ? filename : "report.xlsx");
        // FileDownload(response.data, "report.xlsx");
    });
};

export const executeGetRequest = async (url: string, params?: any, requestConfig?: RequestConfig) => {
    let config: SwcAxiosRequestConfig = {
        params: params,
        successMessage: requestConfig?.successMessage,
        errorMessage: requestConfig?.errorMessage,
        useBackendErrorMessage: requestConfig?.useBackendErrorMessage,
    };
    config.headers = { Authorization: `Bearer ${getToken()}` };
    const response = await apiClient.get(url, config);

    return response;
};

export const executePostRequest = async (url: string, data?: any, requestConfig?: RequestConfig) => {
    let config: SwcAxiosRequestConfig = {
        successMessage: requestConfig?.successMessage,
        errorMessage: requestConfig?.errorMessage,
        useBackendErrorMessage: requestConfig?.useBackendErrorMessage,
    };
    config.headers = { Authorization: `Bearer ${getToken()}` };
    const response = await apiClient.post(url, data, config);

    return response;
};

export const executeDeleteRequest = async (url: string, data?: any, requestConfig?: RequestConfig) => {
    let config: SwcAxiosRequestConfig = {
        successMessage: requestConfig?.successMessage,
        errorMessage: requestConfig?.errorMessage,
        useBackendErrorMessage: requestConfig?.useBackendErrorMessage,
        data: data,
    };
    config.headers = { Authorization: `Bearer ${getToken()}` };
    const response = await apiClient.delete(url, config);

    return response;
};

export const executeRequestDirectly = async (request: Promise<any>) => {
    try {
        await request;
    } catch (e) {
        devConsoleLog(e);
    }
};
