import LandingPageBenefits from "components/landingPageBenefits/landingPageBenefits";
import LandingPageFooter from "components/landingPageFooter/landingPageFooter";
import LandingPageHeader from "components/landingPageHeader/landingPageHeader";
import LandingPageHowToJoin from "components/landingPageHowToJoin/landingPageHowToJoin";
import LandingPageNews from "components/landingPageNews/landingPageNews";
import LandingPageTopNavbar from "components/landingPageTopNavbar/landingPageTopNavbar";
import LandingPageWhatIsEkolure from "components/landingPageWhatIsEkolure/landingPageWhatIsEkolure";
import "./landingPage.scss";

const LandingPage = () => {
    return (
        <>
            <LandingPageTopNavbar />
            <LandingPageHeader />
            <LandingPageNews />
            <LandingPageWhatIsEkolure />
            <LandingPageHowToJoin />
            <LandingPageBenefits />
            <LandingPageFooter />
        </>
    );
};

export default LandingPage;
