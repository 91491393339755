import { Image } from "react-bootstrap";
import "./landingPageAdvantage.scss";

export interface ILandingPageAdvantageProps {
    image: any;
    title: string | JSX.Element;
    description?: string;
}

const LandingPageAdvantage = (props: ILandingPageAdvantageProps) => {
    return (
        <div className="landing-page-advantage-container">
            <div className="landing-page-advantage__image-container">
                <Image
                    src={props.image}
                    className="landing-page-advantage__image"
                />
                <h3 className="landing-page-advantage__title">{props.title}</h3>
                <p className="landing-page-advantage__description">{props.description}</p>
            </div>
        </div>
    );
};

export default LandingPageAdvantage;
