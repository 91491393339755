import { StringResources as Res, translate as t } from "utils/language/languageResource";
import LandingPageAdvantage from "components/landingPageAdvantage/landingPageAdvantage";
import ReduceWaste from "assets/images/landingPageAdvantage/reduce-waste.png";
import ReduceCost from "assets/images/landingPageAdvantage/lower-cost.png";
import MaximazeRecovery from "assets/images/landingPageAdvantage/maximaze-recovery.jpg";
import ReduceCarbonFootprint from "assets/images/landingPageAdvantage/reduce-carbon-footprint.jpg";
import "./landingPageHowToJoin.scss";
import { TopbarNavigation } from "utils/constants";

const LandingPageHowToJoin = () => {
    const landingPageAdvantageList = [
        {
            image: ReduceWaste,
            title: t(Res.ReduceWaste),
            description: t(Res.ReduceWasteDescription),
        },
        {
            image: ReduceCost,
            title: t(Res.ReduceCost),
            description: t(Res.ReduceCostDescription),
        },
        {
            image: MaximazeRecovery,
            title: t(Res.MaximazeRecovery),
            description: t(Res.MaximazeRecoveryDescription),
        },
        {
            image: ReduceCarbonFootprint,
            title: t(Res.ReduceCarbonFootprint),
            description: t(Res.ReduceCarbonFootprintDescription),
        },
    ];

    return (
        <section
            className="landing-page-how-to-join"
            id={TopbarNavigation.howToJoin}
        >
            <div className="landing-page-how-to-join-container">
                <div className="landing-page-how-to-join__header">
                    <h1 className="landing-page-how-to-join__header-title">{t(Res.WhyJoin)}?</h1>
                    <p className="landing-page-how-to-join__header-description">{t(Res.WhyJoinDescription)}</p>
                </div>
                <div className="landing-page-how-to-join__advantages-grid">
                    {landingPageAdvantageList.map(x => (
                        <LandingPageAdvantage
                            image={x.image}
                            title={x.title}
                            description={x.description}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default LandingPageHowToJoin;
